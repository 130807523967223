.insight-media {
    width: 60px;
    height: 45px;
    object-fit: contain;
  }
  .Avator {
    width: 0.9rem;
    height: 0.9rem;
    cursor: pointer;
    margin-top: 0.1rem;
    margin-bottom: 0.1rem;
  }
  .Media-box {
    height: "70px";
    width: "110px";
  }
  .Arrow-icon-center {
    vertical-align: bottom;
  }
  .ResetButton {
    padding: 13px 40px;
  }
  .NoDataMassage {
    justify-content: center;
  }
  .RemoveOverFlow {
    overflow-x: none;
  }
  
  .media-container {
    position: relative;
  }
  
  .trash-icon {
    position: absolute;
    left: 25%;
    top: 0;
  }
  .url_btn_grp {
    display: flex;
    flex-direction: row;
    column-gap: 16px;
    margin-left: 18px;
    align-items: center;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 !important;
    padding-top: 20px !important;
  }
  .url_btn {
    padding: 0 !important;
    display: flex;
    align-items: center;
  
  }
  .url_btn > button {
    padding-left: 12px;
    padding-right: 12px;
    min-width: 100px !important;
    margin-left: 18px;
  }